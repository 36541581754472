import React from "react"
import Layout from "../components/layout"
import Frame from "../components/frame"
import SEO from "../components/seo"

// <Link to="/page-2/">Go to page 2</Link>
const IndexPage = () => (
  <Layout>
    <SEO title="M. Stankevicius" keywords={[`marius`]} />
    <Frame />
  </Layout>
)

export default IndexPage
