import React from "react"
import ImageContainer from "./imageContainer"
import Content from "./content"
import "./frame.css"

const Frame = () => (
    <div className={"frame"}>
        <ImageContainer />
        <Content />
    </div>
)

export default Frame;
