import React from "react"
import Image from "./image"
import "./imageContainer.css"

const ImageContainer = () => (
    <div className={"imageContainer"}>
        <Image />
    </div>
)

export default ImageContainer;
